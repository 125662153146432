<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ creator && typeof creator.user !== 'undefined' ? creator.user.name : '' }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.photos.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <img :src="photo.overlayUrl" width="400" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(photo.created_at).format('LLL')" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-row>
            <v-col>
                <v-btn class="error" @click="dialogDelete = true">Delete</v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5 align-center justify-center text-center full-width">Are you sure you want <br>to delete this photo?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="handleDelete">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </admin-dashboard-layout>
</template>

<script>
    import Photo from "../../../models/admin/Photo";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Creator from "../../../models/admin/Creator";
    import {mapMutations} from "vuex";

    export default {
        name: "photos-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                parent_page: {name: 'admin.photos.index'},
                photo: {},
                creator: null,
                dialogDelete: false,
            }
        },
        watch: {
            async photo(value) {
                if (value && value.creator_id) {
                    this.creator = await Creator.include('user').find(value.creator_id)
                }
            },
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            handleDelete() {
                this.photo.delete().then(() => {
                    this.showSnackBar(this.$t('messages.success'))
                    this.$router.push(this.parent_page)
                }).catch((e) => {
                    console.log(e)
                })
            },
        },
        async mounted() {
            const photoId = this.$route.params.id
            this.photo = await Photo.find(photoId)
        },
    }
</script>

<style scoped>

</style>
